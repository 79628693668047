<template>
    <div @click.prevent="bannerClicked" class="jeweler-ai-banner-wrapper-top">
        <Lottie class="lottie" />
        <div>
            <h3 class="noto-sans-font-title">
                {{ computedText }}
                <span @click.stop class="md-lg-screen"><InfoTooltip :bottom="true" /> &nbsp;</span>

                <b class="noto-sans-kr"
                    >{{ computedBoldText }} <span @click.stop class="sm-screen"><InfoTooltip :bottom="true" /> </span
                ></b>
            </h3>
        </div>

        <div @click.stop="$router.push('/terms-of-use')" class="ad-link">Ad</div>
    </div>
</template>

<script>
import InfoTooltip from "@/willyou/components/baseComponents/InfoTooltip.vue";
export default {
    name: "JewelerAiBanner",
    components: {
        Lottie: () => import("@/willyou/components/Lottie.vue"),
        InfoTooltip,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        computedText() {
            if (this.windowWidth > 600) return "SHOP ENGAGEMENT RINGS WITH JEWELER AI";
            // else if (this.windowWidth <= 800 && this.windowWidth > 624) return "DISCOVER YOUR PERFECT RING!";
            else return "";
        },
        computedBoldText() {
            if (this.windowWidth > 600) return "START NOW!";
            else return "SHOP ENGAGEMENT RINGS";
        },
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        bannerClicked() {
            let utmTerm;
            if (this.$route.name.includes("blog")) {
                if (this.$route.params.category === "local-engagement-rings") utmTerm = "blog_local";
                else if (this.$route.params.category === "best-places-to-propose") utmTerm = "blog_best_place";
                else if (this.$route.params.category === "reviews") utmTerm = "blog_review";
                else utmTerm = "blog";
            } else if (this.$route.name === "home") utmTerm = "home";
            else if (this.$route.name === "find-a-jeweler-by-state") utmTerm = "state";
            else if (this.$route.name === "find-a-jeweler-by-city") utmTerm = "city";
            else if (this.$route.name === "stores-search-results-by-city") utmTerm = "stores_list";
            else if (this.$route.name === "about") utmTerm = "about";
            else if (this.$route.name === "home-store") utmTerm = "jeweler";
            else utmTerm = "other";
            window.open(`https://willyou.com/jewelerai?utm_source=willyou.net&utm_medium=referral&utm_content=top_banner&utm_term=${utmTerm}`, "_blank");
        },
    },
    mounted() {
        window.addEventListener("resize", this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowWidth);
    },
};
</script>

<style lang="scss" scoped>
.jeweler-ai-banner-wrapper-top {
    cursor: pointer;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d9f2ff;
    position: relative;

    .lottie {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }
    h3 {
        color: #ff0a53;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 16px;
        padding: 0;
        letter-spacing: 1px;
        font-weight: 700;
    }
    b {
        margin-left: 14px;
        text-decoration: underline;
        margin: 0;
    }

    .sm-screen {
        display: inline !important;
        @media (min-width: 600px) {
            display: none !important;
        }
    }

    .md-lg-screen {
        display: none !important;
        @media (min-width: 600px) {
            display: inline !important;
        }
    }
}
</style>

<style lang="scss">
.jeweler-ai-banner-wrapper-top {
    .info-tooltip-wrapper {
        text-decoration: unset !important;
        text-transform: none !important;
    }
    @media (max-width: 600px) {
        .info-tooltip {
            bottom: 10px;
            left: unset !important;
            right: -197px;
        }
    }

    @media (max-width: 350px) {
        .info-tooltip {
            bottom: 10px;
            left: unset !important;
            right: -167px;
        }
    }
}
</style>
